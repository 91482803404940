import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`Uruguay and the 2021 national referendum`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Polis used alongside an overstuffed national referendum to reveal which issues are deterministic and which contain breakthrough consensus.
`}<strong parentName="p">{`When:`}</strong>{` 2020 - 2021
`}<strong parentName="p">{`Where:`}</strong>{` Uruguay
`}<strong parentName="p">{`Size:`}</strong>{` 16,000+`}</p>
    <p><strong parentName="p">{`Details:`}</strong>{` In 2020, a newly elected government in Uruguay put their entire party platform into a single bill marked “Urgent,” a special designation meaning that if agreement is not reached by lawmakers within 30 days a bill will be automatically passed. This sprawling and difficult to understand bill contained over 500 articles, from self defense, to education, to the oversight of education, to public safety, to prison overcrowding. Outraged at this, fully a quarter of the country’s total population signed a petition for this bill to instead be put to National Referendum. The referendum still contained the entire bill with over 500 articles. To support the most informed vote possible, a team of 13, including engineers, communications professionals, and data science faculty and staff at Uruguay’s National University set up Polis alongside the referendum to look for deeper understanding than “yes or no.” 16,499 people participated by commenting and casting over 295,557 votes. The referendum happened in early 2021 and, unfortunately for the movement attempting to overturn this bill, the vote was lost by less than 1%. The Polis results showed, among other things, that even if the ultimate divider was public safety, there were other topics and issues where surprising points of consensus broke through. These topics could serve as bridges between groups of people who, before their experience with a wiki survey, were on completely different sides.`}</p>
    <p><strong parentName="p">{`From interview with Raúl Speroni:`}</strong></p>
    <p>{`In 2020-2021, Uruguay was faced with a new government, a new, rather conservative government, after many years of progressive governments. The first thing that the new government did was pass a bill known as Urgent Consideration Law (LUC) with almost 500 articles, from self defense, to education, to the oversight of education (for example, if teachers are allowed or not to have a say in the educational system they are a part of), to public safety, to prison overcrowding. The new government had put their entire programmatic agenda inside an “Urgent Law.” Urgent Laws are treated in the Uruguayan constitution in the following manner: the bill must be sent to Parliament, however after 30 days, if the Parliament does not explicitly discard it, or parts of it, it gets approved by default. That is what happened to many of the original articles.`}</p>
    <p>{`In response, all the social movements and all the left parties from Uruguay got together and started raising signatures to be able to have a referendum over this to revoke this law. The movement raised 800,000 signatures out of a total Uruguay population of 3 million. So that's a lot because what was needed was “25% of the voting population.” The movement raised those signatures with margin to spare, and got the referendum for overthrowing 135 of the remaining articles. The national referendum happened two months ago. The movement to overturn this bill was lost by less than 1%. So that's the story. But what we (the team at Uruguay's National University) did was run a polis while the referendum was active to look for deeper understanding than was “yes or no” on this big, complex, difficult to understand law. Ultimately, the main Polis conversation plus the targeted versions achieved: 16,499 participants, 295,557 answers, on over 83 statements. The results showed, among other things, that even if the main vector of the conversation was public safety, there were other topics and issues that broke the polarization and could become bridges between groups of people that `}<em parentName="p">{`a priori`}</em>{` were in completely different sides of things.`}</p>
    <h4>{`Links:`}</h4>
    <ul>
      <li parentName="ul">{`Open data: `}<a parentName="li" {...{
          "href": "https://github.com/proyectourgente/cuestionario-luc/tree/main/datos"
        }}>{`https://github.com/proyectourgente/cuestionario-luc/tree/main/datos`}</a></li>
      <li parentName="ul">{`Project landing page: `}<a parentName="li" {...{
          "href": "https://Luc.com.uy"
        }}>{`https://Luc.com.uy`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://luc.com.uy/como"
            }}>{`https://luc.com.uy/como`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://luc.com.uy/quienes"
            }}>{`https://luc.com.uy/quienes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://luc.com.uy/privacidad"
            }}>{`https://luc.com.uy/privacidad`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.elpais.com.uy/informacion/politica/experimento-uruguayos-evidencia-matices-detras-polarizacion-luc.html"
        }}>{`https://www.elpais.com.uy/informacion/politica/experimento-uruguayos-evidencia-matices-detras-polarizacion-luc.html`}</a></li>
      <li parentName="ul">
        <undefined parentName="li"><blockquote {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "es",
              "dir": "ltr"
            }}><a parentName="p" {...{
                "href": "https://twitter.com/hashtag/InformeCapital?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#InformeCapital`}</a><br parentName="p"></br><br parentName="p"></br>{`Personas académicas e investigadoras de la `}<a parentName="p" {...{
                "href": "https://twitter.com/Udelaruy?ref_src=twsrc%5Etfw"
              }}>{`@Udelaruy`}</a>{` vinculadas a la ciencias de datos crearon el sitio web `}<a parentName="p" {...{
                "href": "https://t.co/1KBYsqbhgL"
              }}>{`https://t.co/1KBYsqbhgL`}</a>{`, para poner en debate los temas centrales del referéndum del 27 de marzo. `}<a parentName="p" {...{
                "href": "https://t.co/TUutKj5QEA"
              }}>{`pic.twitter.com/TUutKj5QEA`}</a></p>{`— MVD Noticias (@MVDNoticias) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/MVDNoticias/status/1493363353422483459?ref_src=twsrc%5Etfw"
            }}>{`February 14, 2022`}</a></blockquote>{`

`}</undefined>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@raulsperoni/los-apuros-de-la-luc-una-forma-de-hacer-pol%C3%ADtica-y-la-opci%C3%B3n-de-resistirse-c0c28bf2715"
        }}>{`https://medium.com/@raulsperoni/los-apuros-de-la-luc-una-forma-de-hacer-pol%C3%ADtica-y-la-opci%C3%B3n-de-resistirse-c0c28bf2715`}</a></li>
      <li parentName="ul">{`About the referendum: `}<a parentName="li" {...{
          "href": "https://es.wikipedia.org/wiki/Refer%C3%A9ndum_sobre_la_Ley_de_Urgente_Consideraci%C3%B3n_(Uruguay)"
        }}>{`https://es.wikipedia.org/wiki/Refer%C3%A9ndum_sobre_la_Ley_de_Urgente_Consideraci%C3%B3n_(Uruguay)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      